import React from "react";
import "./index.scss";
import Logo from '../../assets/img/footer/footer-logo.png';
import { NavLink } from "react-router-dom";

const Footer = ({ redirectToRegistration }) => {
  const handleEmail = () => {
    window.location.href = "mailto:adastraschool2024@gmail.com";
  };

  return (
    <div className="footer-container">
      <div className="footer-box">
        <div className="top-box">
          <div className="logo-box">
            <a href="#">
              <img src={Logo} alt="logo" />
            </a>
          </div>
          <div className="menu-box">
            <h2>Меню</h2>
            <ul className="menu-subbox">
              <li>
                <a href="#target">Для кого курс?</a>
              </li>
              <li>
                <a href="#about">Про курс</a>
              </li>
              <li>
                <a href="#description">Опис курсу</a>
              </li>
              <li>
                <a href="#result">Що ти отримуєш?</a>
              </li>
              <li>
                <a href="#author">Про автора</a>
              </li>
            </ul>
          </div>
          <div className="resource-box">
            <h2>Ресурси</h2>
            <ul className="resource-subbox">
              <li>
                <NavLink to={"security"}>Політика безпеки</NavLink>
              </li>
              <li>
                <NavLink to={"refund"}>Політика повернення платежів</NavLink>
              </li>
              <li>
                <NavLink to={"privacy"}>Політика конфіденційності</NavLink>
              </li>
              <li>
                <NavLink to={"contract"}>Публічний договір</NavLink>
              </li>
            </ul>
          </div>
          <div className="register-box">
            <h2 className="register-title">Зареєструйся на курс</h2>
            <button onClick={redirectToRegistration}>Записатися</button>
            <div className="register-subbox">
              <p className="email-link-btn" onClick={handleEmail}>
              adastraschool2024@gmail.com
              </p>
              <div className="social-box">
                <div
                  className="insta-icon icon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/ad_astra_english_?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr",
                      "_blank"
                    )
                  }
                ></div>
                <div
                  className="fb-icon icon"
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/profile.php?id=61555731793404`,
                      "_blank"
                    )
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-box">
          <p>Copyright © 2023 LOGO | All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
