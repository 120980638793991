import React from 'react';
import "./index.scss";

const Description = ({ redirectToRegistration }) => {
  return (
    <div className='description-container container' id='description'>
      <div className='description-box box'>
        <div className='title'>
          <h2 className='heading2'>Опис курсу</h2>
          <p className='paragraph'>Кожний з відео-уроків курсу – це подорож крізь три часові аспекти: теперішність, минулість та майбутність, а характерні для усіх з них 4 часові категорії дадуть вам можливість зрозуміти звідки у англійській аж 12 часів! І нарешті доведуть, що саме ці конструкції належать найлогічнішій системі побудови думок в усій мовній картині світу! Візуальні схеми, використані в курсі, слугуватимуть переконливою підтримкою розуміння усних пояснень викладача.</p>
          <p className='paragraph'>Попри засвоєння правил використання часових та похідних конструкцій у побудованих кумедно-цікавих контекстах, які можна з легкістю видозмінювати в залежності від ваших особистих комунікативних потреб, ви додатково отримаєте завдання від Равлика Едді – головного персонажа усіх часових категорій – та разом з викладачем розберете помилки і нюанси потенційних складнощів, що неминуче трапляються на усіх етапах навчання!</p>
        </div>
        <div className='description-btn-box' onClick={redirectToRegistration}></div>
      </div>
    </div>
  )
}

export default Description;