import Logo from "../../assets/img/pages/pages-logo.png";
import { NavLink, Link } from "react-router-dom";

const Contract = () => {
  return (
    <div className="page-container">
      <div className="page-box">
        <div className="logo-box">
          <Link to={"/"}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="info-box">
          <div className="box-top">
            <NavLink to="/">Головна</NavLink>
            <span></span>
            <h3>Публічний договір</h3>
          </div>

          <div className="box-mid">
            <h4>ПУБЛІЧНИЙ ДОГОВІР</h4>
          </div>

          <p>
            (ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ)про надання послуг у сфері підготовки до
            зовнішнього незалежного оцінювання Цей Договір, в якому одна сторона
            Фізична особа – підприємець Мельник Андрій Володимирович,
            зареєстрований в Єдиному державному реєстрі юридичних осіб та
            фізичних осіб – підприємців в установленому чинним законодавством
            порядку та який є платником єдиного податку (надалі – Виконавець), з
            одного боку, і будь-яка особа, яка прийняла (акцептувала) дану
            пропозицію (надалі – Замовник), з іншого боку, далі разом — Сторони,
            а кожний окремо – Сторона, уклали даний Договір (далі — Договір),
            адресований необмеженому колу осіб, який є офіційною публічною
            пропозицією Виконавця, укласти з будь-яким Замовником Договір про
            надання послуг у сфері освіти. При замовленні та оплаті Послуг
            Виконавця, Замовники приймають умови цього Договору про
            нижченаведене.
            <br /><br />
            1. ЗАГАЛЬНІ ПОЛОЖЕННЯ
            <br />
            1.1. Цей Договір укладається шляхом надання повної й безумовної згоди (акцепту) Замовника на укладення Договору в повному обсязі, без підпису письмового примірника Договору Сторонами.
            <br />
            1.2. Договір має юридичну силу відповідно до ст.ст. 633, 641, 642 Цивільного кодексу України і є рівносильним Договору, підписаному Сторонами.
            <br />
            1.3. Замовник підтверджує факт ознайомлення та згоди з усіма умовами цього Договору в повному обсязі шляхом акцептування.
            <br />
            1.4. Будь-яка з наступних дій вважається акцептом цього договору публічної оферти:
            <br />
            – факт реєстрації Замовника на Веб-сайті Виконавця та оформлення Замовлення Послуг Виконавця на веб-сайті Виконавця https://englishtenses.com.ua/
            <br />
            – оплата Послуг Виконавця на умовах та в порядку, визначеними цим Договором та на відповідних сторінках Веб-сайту Виконавця https://englishtenses.com.ua/
            <br />
            1.5. Укладаючи цей Договір Замовник автоматично погоджується з повним та безумовним прийняттям положень цього Договору і цін на Послуги.
            <br />
            1.6. Якщо Замовник не згодний з умовами Договору, він не має права укладати цей Договір, а також не вправі користуватися Послугами за цим Договором.
            <br /><br />
            2. ТЕРМІНИ І ВИЗНАЧЕННЯ 
            <br />
            «Договір публічної оферти» - публічний договір, зразок якого розміщений на Веб-сайті https://englishtenses.com.ua/. «Акцепт» - надання Замовником повної й безумовної згоди на укладення даного Договору в повному обсязі, без підпису письмового примірника Договору Сторонами. 
            <br />
            «Послуги» – Послуги в сфері освіти а саме – доступ Замовника чи особи в чиїх інтересах виступає Замовник до запису онлайн-курсу «English tenses: Weekend with Eddie», які надаються Виконавцем, та зазначені Виконавцем у відповідному розділі Веб-сайту Виконавця  https://englishtenses.com.ua/
            <br />
            «Замовник» - будь-яка дієздатна фізична, фізична особа-підприємець, що відвідала Веб-сайт https://englishtenses.com.ua/ та акцептувала даний Договір.
            <br />
            «Виконавець» - фізична особа підприємець, що надає послуги у сфері освіти до та реквізити якого зазначено у розділі 12 даного Договору.
            <br />
            «Замовлення» - належно оформлена заявка Замовника на отримання Послуг, що адресується Виконавцю.
            <br /><br />
            3. ПРЕДМЕТ ДОГОВОРУ 
            <br />
            3.1.Виконавець зобов’язується на умовах та в порядку, визначених цим Договором, надати Замовнику Послуги в сфері освіти а саме – доступ Замовника чи особи в чиїх інтересах виступає Замовник до запису онлайн-курсу «English tenses: Weekend with Eddie», а Замовник зобов’язується на умовах та в порядку, визначених цим Договором, прийняти та оплатити замовлені Послуги.
            <br />
            3.2. Замовник та Виконавець підтверджують, що даний Договір не є фіктивним чи удаваним правочином або правочином, укладеним під впливом тиску чи обману.
            <br />
            3.3. Виконавець підтверджує, що має право на здійснення діяльності у сфері освіти, у відповідності з вимогами діючого законодавства України. 
            <br /><br />
            4. ПРАВА ТА ОБОВ’ЯЗКИ ВИКОНАВЦЯ 
            <br />
            4.1. Виконавець зобов’язаний:
            <br />
            · виконувати умови даного Договору;
            <br />
            · надати Замовнику Послуги належної якості;'
            <br />
            · об’єктивно інформувати Замовника про Послуги та умови їх надання на Веб-сайті https://englishtenses.com.ua/.
            <br />
            4.2. Виконавець має право:
            <br />
            · в односторонньому порядку призупинити надання послуг за цим Договором у випадку порушення Замовником умов цього Договору;
            <br />
            · інші права відповідно до чинного законодавства України та цього Договору.
            <br /><br />
            5. ПРАВА ТА ОБОВ’ЯЗКИ ЗАМОВНИКА 
            <br />
            5.1. Замовник зобов’язаний:
            <br />
            · своєчасно оплатити і отримати Замовлення на умовах цього Договору;
            <br />
            · ознайомитись з інформацією про Послуги, яка розміщена на сайті Виконавця.
            <br />
            5.2. Замовник має право:
            <br />
            · оформити Замовлення Послуг, зазначених на відповідній сторінці Веб-сайту https://englishtenses.com.ua/
            <br />
            · вимагати від Виконавця надання Послуг відповідно до умов цього Договору;
            <br />
            · інші права відповідно до чинного законодавства України та цього Договору.
            <br /><br />
            6. ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ 
            <br />
            6.1. Замовник самостійно оформлює Замовлення на відповідній сторінці Веб-сайту https://englishtenses.com.ua/
            <br />
            6.2. Строк обробки Виконавцем Замовлення – до 3-х (трьох) робочих днів з моменту його оформлення. У разі, якщо Замовлення відправлено у вихідний або святковий день, строк обробки Замовлення починається з першого після вихідного робочого дня.
            <br /><br />
            7. ЦІНА ДОГОВОРУ І ПОРЯДОК ОПЛАТИ 
            <br />
            7.1. Ціна Послуг визначається Виконавцем та зазначається на відповідній сторінці Веб-сайту:https://englishtenses.com.ua/
            <br />
            7.2. Замовник оплачує Послуги Виконавця на підставі цього Договору у Національній валюті України – гривні. 
            <br />
            7.3. Оплата Послуг здійснюється шляхом:
            <br />
            7.3.1. перерахування грошових коштів на поточний
            рахунок Виконавця або
            <br />
            7.3.2. за допомогою інших платіжних засобів,
            які вказані на Веб-сайті Виконавця. 
            <br />
            7.4. Моментом оплати Послуг вважається час зарахування грошових коштів на поточний рахунок Виконавця.
            <br />
            7.5. Замовник самостійно та за свій рахунок сплачує вартість послуг третіх осіб, якщо це необхідно для отримання Послуг Виконавця за цим Договором (наприклад, послуги доступу до мережі Інтернет та інші).
            <br /><br />
            8. ПОРЯДОК ОТРИМАННЯ ПОСЛУГ. 
            <br />
            ПОРЯДОК ПРИЙМАННЯ-ПЕРЕДАЧІ НАДАНИХ ПОСЛУГ. 
            <br />
            8.1. Правила надання та отримання Послуг зазначені на відповідній сторінці Веб-сайту https://englishtenses.com.ua/. Усі питання, що виникли в процесі оплати і отримання Послуг, Замовник може з'ясувати у Виконавця за контактними даними, зазначеними на сайті https://englishtenses.com.ua/ або в розділі 12 даного Договору.
            <br /><br />
            9. ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ 
            <br />
            9.1. За невиконання або неналежне виконання своїх зобов’язань за цим Договором, сторони несуть відповідальність відповідно до чинного законодавства України.
            <br />
            9.2. Усі спори, що виникають з цього Договору або пов'язані із ним, вирішуються шляхом переговорів між Сторонами.
            <br />
            9.3. Якщо відповідний спір не можливо вирішити шляхом переговорів, він вирішується в судовому порядку за встановленою підвідомчістю та підсудністю такого спору відповідно до чинного в Україні законодавства.
            <br />
            9.4. Виконавець не несе будь-яку відповідальність за ненадання або неналежне надання Послуг Замовнику за умови настання будь-яких обставин, які виникли не з вини Виконавця (а саме – настання обставин, які виникли з вини чи необережності Замовника та / або настання обставин, які виникли з вини чи необережності будь-якої третьої особи (будь-яких третіх осіб) та /або настання форс-мажорних обставин).
            <br /><br />
            10. ФОРС-МАЖОРНІ ОБСТАВИНИ 
            <br />
            10.1. Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов'язань, що передбачені даним Договором, якщо воно виникло внаслідок форс-мажорних обставин.
            <br />
            10.2. Під форс-мажорними обставинами у даному Договорі розуміються будь-які обставини, що виникли поза волею або всупереч волі чи бажанню Сторін і яких не можна передбачити чи уникнути, включаючи: військові дії, громадські заворушення, епідемії, блокаду, землетруси, повені, пожежі, а також рішення чи приписи органів державної влади та управління держави, резидентом якої є Замовник, або держави, резидентом якої є Виконавець, внаслідок яких на Сторони (або одну із Сторін) покладатимуться додаткові обов'язки чи встановлюватимуться додаткові обмеження і які роблять неможливим подальше повне або часткове виконання Договору, а також інші дії чи події, що існують поза волею Сторін.
            <br />
            10.3. Якщо форс-мажорні обставини триватимуть більше 3-х (трьох) місяців поспіль, то кожна із Сторін матиме право відмовитись від подальшого виконання зобов'язань за цим Договором і, в такому разі, жодна із Сторін не матиме права на відшкодування іншою Стороною можливих збитків.
            <br /><br />
            11. ІНШІ УМОВИ ДОГОВОРУ 
            <br />
            11.1. Кожна Сторона гарантує іншій Стороні, що володіє необхідною дієздатністю, а рівно всіма правами і повноваженнями, необхідними і достатніми для укладання і виконання цього Договору відповідно до його умов.
            <br />
            11.2.
            Одностороння зміна умов укладеного Договору Замовником чи відмова виконувати умови укладеного Договору Замовником є неприпустимою, за винятків випадків, передбачених цим Договором. Жодна зі Сторін цього Договору не має права передавати свої права та обов’язки третім особам без згоди на це другої Сторони.
            <br />
            11.3. Виконавець підтверджує, що є платником єдиного податку (група 3 ) за ставкою, передбаченою Податковим кодексом України.
            <br />
            11.4. Інформація, що надається Замовником є конфіденційною. Інформація про Замовника використовується виключно в цілях виконання його Замовлення.
            <br />
            11.5. Власним акцептуванням Договору Замовник добровільно надає згоду на збір та обробку власних персональних даних з наступною метою: дані, що стають відомі, використовуватимуться в комерційних цілях, в тому числі отримання інформації про замовлення та обробки інформації про нього, надсилання телекомунікаційними засобами зв’язку (електронною поштою, мобільним зв’язком) рекламних та спеціальних пропозицій, інформації про акції або будь-якої іншої інформації про діяльність Веб-сайту https://englishtenses.com.ua/. У випадку небажання отримувати інформацію про діяльність Веб-сайту https://englishtenses.com.ua/, Замовник має право звернутися до Виконавця і повідомити про відмову від отримання рекламних матеріалів.
            <br />
            11.6. Виконавець не несе відповідальність за зміст і правдивість інформації, що надається Замовником при оформленні Замовлення. Замовник несе відповідальність за достовірність вказаної при оформленні Замовлення інформації.
            <br />
            11.7. Замовнику надається право на використання Послуг Виконавця виключно у своїй внутрішній діяльності без права їх відчуження або передачі на користь третіх осіб.
            <br />
            11.8. Сторони зобов’язуються зберігати конфіденційну інформацію, отриману в результаті виконання цього Договору, за винятком випадків, коли це письмово санкціоновано іншою Стороною або вимагається державними органами відповідно до чинного законодавства. За розголошення конфіденційної інформації винна Сторона несе відповідальність згідно з чинним законодавством.
            <br />
            11.9. Договір є публічним і діє до його припинення у порядку, встановленому цим Договором. Цей Договір вважається погодженим Замовником та укладеним за місцезнаходженням Виконавця з дати акцептування.
            <br />
            11.10. Виконавець самостійно у відповідності та на виконання вимог чинного законодавства України визначає умови даного договору. Виконавець має право самостійно змінити та/або доповнити умови цього публічного Договору, включаючи правила надання та отримання Послуг за даним Договором. При цьому Виконавець гарантує та підтверджує, що розміщена на Веб-сайті Виконавця поточна редакція тексту цього Договору, включаючи правила надання та отримання Послуг за даним Договором, є дійсною.
            <br /><br />
            12. РЕКВІЗИТИ ВИКОНАВЦЯ 
            <br />
            Фізичнаособа-підприємець Мельник Наталія Олегівна
            <br />
            Місцезнаходження: Львівська обл., Пустомитівський район, с. Солонка вул. О. Олеся,27
            <br />
            Код за ЄДРПОУ: 3261705226
            <br />
            Банківські реквізити: UA173052990000026004031002902 
            <br />
            Львівське ГРУ АТ КБ «ПРИВАТБАНК»
            <br />
            E-mail: natamelnyk1188@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contract;
