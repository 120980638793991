import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/normalize.css";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import App from "./App";
import Security from "./pages/Security";
import Refund from "./pages/Refund";
import Privacy from "./pages/Privacy";
import Contract from "./pages/Contract";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Router>
//       <Routes>
//         <Route exact path="/" component={App} />
//         <Route path="/security" component={Security} />
//         <Route path="/refund" component={Refund} />
//         <Route path="/privacy" component={Privacy} />
//         <Route path="/contract" component={Contract} />
//         <Route path="*" component={App} />
//       </Routes>
//     </Router>
//   </React.StrictMode>
// );

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     errorElement: <App />,
//   },
//   {
//     path: "/security",
//     element: <Security />,
//   },
//   {
//     path: "/refund",
//     element: <Refund />,
//   },
//   {
//     path: "/privacy",
//     element: <Privacy />,
//   },
//   {
//     path: "/contract",
//     element: <Contract />,
//   },
//   {
//     path: "*",
//     element: <Navigate to={"/"}/>
//   },
// ]);
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router}/>
//   </React.StrictMode>
// );

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/security",
    Component: Security,
  },
  {
    path: "/refund",
    element: <Refund />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/contract",
    element: <Contract />,
  },
  {
    path: "/*",
    element: <Navigate to={"/"}/>
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);