import { NavLink, Link } from "react-router-dom";
import Logo from "../../assets/img/pages/pages-logo.png";

const Refund = () => {
  return (
    <div className="page-container">
      <div className="page-box">
        <div className="logo-box">
          <Link to={"/"}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="info-box">
          <div className="box-top">
            <NavLink to="/">Головна</NavLink>
            <span></span>
            <h3>Політика повернення платежів</h3>
          </div>

          <div className="box-mid">
            <h4>Політика повернення платежів</h4>
          </div>

          <p>Трапляються ситуації, коли при роботі з сайтом https://englishtenses.com.ua/ користувачі помилково оплачують послуги. Тому з огляду на потреби та побажання своїх клієнтів, ФОП Мельник Н. О. розробив «Політику повернення платежів».
          Особи , що оплатили послуги, мають можливість звернутися до ФОП Мельник Н. О. з метою повернення коштів за контактами, які вказані на сайті.
          Рішення про відмову / повернення сплачених коштів приймається ФОП Мельник Н. О. в односторонньому порядку. При прийнятті даного рішення ФОП Мельник Н. О. керується наступними правилами:
          </p>

          <p>1. Вартість оплати послуг може бути повернута замовнику лише протягом одного тижня від моменту оплати послуг.<br />
          2. Повернення грошових коштів здійснюється лише на ті реквізити, за якими був здійснений платіж. Рекомендуємо зберігати документ, який підтверджує здійснення платежу.<br />
          3. Після звернення із запитом про повернення оплати послуг і схвальному рішенні ФОП Мельник Н. О. замовнику необхідно надати ФОП Мельник А. В. такі дані: банківські реквізити, на які необхідно перерахувати суму повернення; контактні дані для зв'язку - телефон і адресу електронної пошти.
          <br />
          4. У разі позитивного рішення про повернення, кошти будуть перераховані на зазначені в заяві реквізити протягом 30 календарних днів.<br />
          5. Кошти не повертаються, якщо клієнт придбав нетривалі послуги, реалізація яких передбачена за 1-30 днів.<br />
          6. Кошти не повертаються, якщо клієнт придбав послуги, у яких клієнту відразу відкривається доступ до всіх необхідних матеріалів.</p>
        </div>
      </div>
    </div>
  );
};

export default Refund;
