import React from 'react'
import { NavLink, Link } from "react-router-dom";
import "../index.scss";
import Logo from "../../assets/img/pages/pages-logo.png";

const Privacy = () => {
  return (
    <div className="page-container">
      <div className="page-box">
        <div className="logo-box">
          <Link to={"/"}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="info-box">
          <div className="box-top">
            <NavLink to="/">Головна</NavLink>
            <span></span>
            <h3>Політика конфіденційності</h3> 
          </div>

          <div className="box-mid">
            <h4>Політика конфіденційності</h4>
            <p>Угода про згоду на обробку персональних даних. Сторони:</p>
          </div>

          <p>Замовник будь-яка дієздатна фізична, фізична особа-підприємець, що замовила послуги у освіти на сайті https://englishtenses.com.ua// та Виконавець (фізична особа – підприємець Мельник Наталія Олегівна, зареєстрований в Єдиному державному реєстрі юридичних осіб та фізичних осіб – підприємців в установленому чинним законодавством порядку та який є платником єдиного податку) приймають ці положення та погоджуються з ними:
          <br />
          Персональні дані - будь-яка інформація про Замовника, у тому числі його ім'я, номер телефону та email, а також інша інформація, яку Замовник вказує на свій розсуд.
          </p>

          <p>Під обробкою персональних даних Замовника розуміються дії (операції) Виконавця зперсональними даними, включаючи збір, систематизацію, накопичення, зберігання,
          уточнення (оновлення, зміну), використання, знищення персональних даних.
          Виконавець інформує Замовника, що всі персональні дані Замовника використовуються
          лише з метою підготовки та організації якісних освітніх послуг .
          </p>

          <p>Мета надання Замовником персональних даних і подальшої обробки їх Виконавцем -
          отримання Замовником якісних освітніх послуг Виконавця .
          Виконавець зобов'язується не надавати ніякої персональної інформації про Замовника
          іншим приватним особами і організаціям.
          Замовник надає Виконавцю право здійснювати наступні дії (операції) з персональними
          даними: збір та накопичення; безстрокове зберігання, уточнення (оновлення, зміну);
          використання; знищення; знеособлення.
          Відправка Замовником виконавцю інформації про себе в електронному вигляді є його безумовною згодою на обробку його персональних даних.</p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;