import React from 'react';
import "./index.scss";

const Target = ({ redirectToRegistration }) => {
  return (
    <div className='target-container container' id='target'>
      <div className='target-box box'>
        <div className='title'>
          <h2 className='heading2'>Кому підходить?</h2>
          <p className='paragraph'>Усім, хто прагне врешті зрозуміти логіку часових конструкцій і назавжди попрощатися з нав’язливою ідеєю, що англійські часи – це страшний сон! Якщо ви зацікавлені у покращенні свого рівня володіння англійською мовою, прагнете вільно висловлювати свої думки, долаючи страх говоріння іноземною мовою, але у вас, як завжди, дуже обмежений ресурс часу, то цей міні-екскурс по часах саме для вас, адже займе лише один з вікендів у вашому житті. </p>
          <p className='paragraph'>Подаруйте собі цю чудову подорож в часі, де ще ніхто не побував! І, повірте, це буде найяскравіша оповідь про вихідні у понеділок на каві-брейку!</p>
        </div>
        <div className='target-btn-box' onClick={redirectToRegistration}></div>
      </div>
    </div>
  )
}

export default Target;