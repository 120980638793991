import { NavLink, Link } from "react-router-dom";
import Logo from "../../assets/img/pages/pages-logo.png";

const Security = () => {
  return (
    <div className="page-container">
      <div className="page-box">
        <div className="logo-box">
          <Link to={"/"}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="info-box">
          <div className="box-top">
            <NavLink to="/">Головна</NavLink>
            <span></span>
            <h3>Політика безпеки передачі реквізитів платіжної картки</h3>
          </div>

          <div className="box-mid">
            <h4>Політика безпеки передачі реквізитів платіжної картки</h4>
          </div>

          <p>При оплаті замовлення банківською картою обробка платежу (включаючи введення номера картки) відбувається на захищеній сторінці процесингової системи. Це означає, що Ваші конфіденційні дані (реквізити картки, реєстраційні дані та ін.) не надходять до нас, їх обробка повністю захищена і ніхто не може отримати персональні та банківські дані клієнта. Під час роботи з картковими даними застосовується стандарт захисту інформації, розроблений міжнародними платіжними системами Visa та MasterCard – Payment Card Industry Data Security Standard (PCI DSS), що забезпечує безпечну обробку реквізитів Банківської картки Держателя. Технологія передачі даних, що застосовується, гарантує безпеку по операціях з Банківськими картами шляхом використання протоколів Secure Sockets Layer (SSL)."
          </p>
        </div>
      </div>
    </div>
  );
};

export default Security;